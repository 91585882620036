import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from '../data.service';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from "@angular/router";

import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { ComponentRestrictions } from "ngx-google-places-autocomplete/objects/options/componentRestrictions";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import swal from 'sweetalert2';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  form: FormGroup;

  account: Object;
  countries: Object;
  options: Object;

  accountType: string = "agency";
  accountPlan: string = "allyplus";
  disableCity: boolean = true;
  hidePassword: boolean = true;
  hidePasswordConfirm: boolean = true;
  submitLoading: boolean = false;
  formSubmitAttempt: boolean = false;

  cityCode: string;
  cityName: string;
  ipAddress: string;

  @ViewChild("placesRef") placesRef : GooglePlaceDirective;

  constructor(
    private data: DataService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      'username': [null, [Validators.required]],
      'name': [null, [Validators.required]],
      'email': [null, [Validators.required, Validators.email]],
      'phone': [null, [Validators.required]],
      'cnpj': [null, [Validators.nullValidator]],
      'corporate_name': [null, [Validators.nullValidator]],
      'country': [null, [Validators.required]],
      'city': [{value: null, disabled: true}, [Validators.required]],
      'password': [null, [Validators.required]],
      'passwordConfirm': [null, [Validators.required]],
      'signature': [null]
    });

    this.data.getCountries().subscribe(
      data => this.countries = data
    )
  }

  onChangeCountry(code: any) {
    // this.placesRef.el.nativeElement.value = '';
    // this.form.controls.city.value = '';
    this.placesRef.options.componentRestrictions = new ComponentRestrictions({
      country: code.value
    });
    this.placesRef.reset();
    this.form.controls.city.enable();
  }

  handleAddressChange(place: Address) {
    this.cityCode = place.id;
    this.cityName = place.name;
  }

  changeAccountType(type: string) {
    this.accountType = type;
    var flightIcon = <HTMLImageElement>document.getElementById("flight-icon");
    var mortarboardIcon = <HTMLImageElement>document.getElementById("mortarboard-icon");
    // var marketingImage = <HTMLImageElement>document.getElementById("marketing-image");
    if (type == 'agency') {
      flightIcon.src = "/assets/icons/flight-active.svg";
      mortarboardIcon.src = "/assets/icons/mortarboard.svg";
      // marketingImage.src = "/assets/Post-001.png";
    }
    else if (type == 'school') {
      flightIcon.src = "/assets/icons/flight.svg";
      mortarboardIcon.src = "/assets/icons/mortarboard-active.svg";
      // marketingImage.src = "/assets/Post-001.png";
    }
  }

  changeAccountPlan(plan: string) {
    this.accountPlan = plan;
    var flightIcon = <HTMLImageElement>document.getElementById("flight-icon");
    var mortarboardIcon = <HTMLImageElement>document.getElementById("mortarboard-icon");
    // var marketingImage = <HTMLImageElement>document.getElementById("marketing-image");
    if (plan == 'allyplus') {
      flightIcon.src = "/assets/icons/flight-active.svg";
      mortarboardIcon.src = "/assets/icons/mortarboard.svg";
      // marketingImage.src = "/assets/Post-001.png";
    }
    else if (plan == 'backoffice') {
      flightIcon.src = "/assets/icons/flight.svg";
      mortarboardIcon.src = "/assets/icons/mortarboard-active.svg";
      // marketingImage.src = "/assets/Post-001.png";
    }
  }

  onContinueTwo() {
    // STEP 1
    var divStepOne = document.getElementById("step-1");
    var arrOne = divStepOne.className.split(" ");
    if (arrOne.indexOf("animated") == -1) {
      divStepOne.className += " " + "animated";
    }
    if (arrOne.indexOf("fadeOutLeft") == -1) {
      divStepOne.className += " " + "fadeOutLeft";
    }
    var step = 2;
    var eventNames = "animationend oAnimationEnd mozAnimationEnd webkitAnimationEnd MSAnimationEnd";
    eventNames.split(' ').forEach(e => divStepOne.addEventListener(e, this.handleContinue(step), false));
    if (this.accountType == 'school') {
      return this.onContinueThree();
    }
  }

  onContinueThree() {
    // STEP 1
    var divStepTwo = document.getElementById("step-2");
    var arrTwo = divStepTwo.className.split(" ");
    if (arrTwo.indexOf("animated") == -1) {
      divStepTwo.className += " " + "animated";
    }
    if (arrTwo.indexOf("fadeOutLeft") == -1) {
      divStepTwo.className += " " + "fadeOutLeft";
    }
    var step = 3;
    var eventNames = "animationend oAnimationEnd mozAnimationEnd webkitAnimationEnd MSAnimationEnd";
    eventNames.split(' ').forEach(e => divStepTwo.addEventListener(e, this.handleContinue(step), false));
  }

  onBackTwo() {
    // STEP 2
    var divStepTwo = document.getElementById("step-2");
    var arrTwo = divStepTwo.className.split(" ");
    if (arrTwo.indexOf("animated") == -1) {
      divStepTwo.className += " " + "animated";
    }
    divStepTwo.className = divStepTwo.className.replace(/\b fadeInRight\b/g, "");
    if (arrTwo.indexOf("fadeOutRight") == -1) {
      divStepTwo.className += " " + "fadeOutRight";
    }
    var step = 1;
    var eventNames = "animationend oAnimationEnd mozAnimationEnd webkitAnimationEnd MSAnimationEnd";
    eventNames.split(' ').forEach(e => divStepTwo.addEventListener(e, this.handleBack(step), false));
  }

  onBackThree() {
    // STEP 3
    var divStepThree = document.getElementById("step-3");
    var arrThree = divStepThree.className.split(" ");
    if (arrThree.indexOf("animated") == -1) {
      divStepThree.className += " " + "animated";
    }
    divStepThree.className = divStepThree.className.replace(/\b fadeInRight\b/g, "");
    if (arrThree.indexOf("fadeOutRight") == -1) {
      divStepThree.className += " " + "fadeOutRight";
    }

    var step = 2;
    var eventNames = "animationend oAnimationEnd mozAnimationEnd webkitAnimationEnd MSAnimationEnd";
    eventNames.split(' ').forEach(e => divStepThree.addEventListener(e, this.handleBack(step), false));
    if (this.accountType == 'school') {
      return this.onBackTwo();
    }
  }

  handleContinue(step: number): any {
    if (step == 2) {
      var divStepOne = document.getElementById("step-1");
      var arrOne = divStepOne.className.split(" ");
      divStepOne.className = divStepOne.className.replace(/\b animated\b/g, "");
      divStepOne.className = divStepOne.className.replace(/\b fadeOutLeft\b/g, "");
      divStepOne.className = divStepOne.className.replace(/\b fadeInLeft\b/g, "");
      if (arrOne.indexOf("sl-hide") == -1) {
        divStepOne.className += " " + "sl-hide";
      }
      // STEP 2
      var divStepTwo = document.getElementById("step-2");
      var arrTwo = divStepTwo.className.split(" ");
      if (arrTwo.indexOf("animated") == -1) {
        divStepTwo.className += " " + "animated";
      }
      if (arrTwo.indexOf("fadeInRight") == -1) {
        divStepTwo.className += " " + "fadeInRight";
      }
      divStepTwo.className = divStepTwo.className.replace(/\b sl-hide\b/g, "");
    }
    if (step == 3) {
      var divStepTwo = document.getElementById("step-2");
      var arrOne = divStepTwo.className.split(" ");
      divStepTwo.className = divStepTwo.className.replace(/\b animated\b/g, "");
      divStepTwo.className = divStepTwo.className.replace(/\b fadeOutLeft\b/g, "");
      divStepTwo.className = divStepTwo.className.replace(/\b fadeInLeft\b/g, "");
      if (arrOne.indexOf("sl-hide") == -1) {
        divStepTwo.className += " " + "sl-hide";
      }
      // STEP 3
      var divStepThree = document.getElementById("step-3");
      var arrThree = divStepThree.className.split(" ");
      if (arrThree.indexOf("animated") == -1) {
        divStepThree.className += " " + "animated";
      }
      if (arrThree.indexOf("fadeInRight") == -1) {
        divStepThree.className += " " + "fadeInRight";
      }
      divStepThree.className = divStepThree.className.replace(/\b sl-hide\b/g, "");
    }
  }

  handleBack(step: number): any {
    if (step == 1) {
      var divStepTwo = document.getElementById("step-2");
      var arrTwo = divStepTwo.className.split(" ");
      divStepTwo.className = divStepTwo.className.replace(/\b animated\b/g, "");
      divStepTwo.className = divStepTwo.className.replace(/\b fadeOutRight\b/g, "");
      divStepTwo.className = divStepTwo.className.replace(/\b fadeInRight\b/g, "");
      if (arrTwo.indexOf("sl-hide") == -1) {
        divStepTwo.className += " " + "sl-hide";
      }
      // STEP 1
      var divStepOne = document.getElementById("step-1");
      var arrOne = divStepOne.className.split(" ");
      if (arrOne.indexOf("animated") == -1) {
        divStepOne.className += " " + "animated";
      }
      if (arrOne.indexOf("fadeInLeft") == -1) {
        divStepOne.className += " " + "fadeInLeft";
      }
      divStepOne.className = divStepOne.className.replace(/\b sl-hide\b/g, "");
    }
    if (step == 2) {
      var divStepThree = document.getElementById("step-3");
      var arrThree = divStepThree.className.split(" ");
      divStepThree.className = divStepThree.className.replace(/\b animated\b/g, "");
      divStepThree.className = divStepThree.className.replace(/\b fadeOutRight\b/g, "");
      divStepThree.className = divStepThree.className.replace(/\b fadeInRight\b/g, "");
      if (arrThree.indexOf("sl-hide") == -1) {
        divStepThree.className += " " + "sl-hide";
      }
      // STEP 2
      var divStepTwo = document.getElementById("step-2");
      var arrTwo = divStepTwo.className.split(" ");
      if (arrTwo.indexOf("animated") == -1) {
        divStepTwo.className += " " + "animated";
      }
      if (arrTwo.indexOf("fadeInRight") == -1) {
        divStepTwo.className = divStepThree.className.replace(/\b fadeInLeft\b/g, "");
        divStepTwo.className += " " + "fadeInLeft";
      }
      else{
        divStepTwo.className = divStepThree.className.replace(/\b fadeInRight\b/g, "");
        divStepTwo.className += " " + "fadeInLeft";
      }
      divStepTwo.className = divStepTwo.className.replace(/\b sl-hide\b/g, "");
    }
  }

  async onSubmit() {
    this.submitLoading = true;
    this.formSubmitAttempt = true;
    if (this.form.valid) {
      if (this.form.controls.password.value == this.form.controls.passwordConfirm.value) {
        if (this.form.controls.signature.value) {
          this.account = {
            type: (this.accountType == "school") ? 2 : 1,
            plan: (this.accountPlan == "allyplus") ? 1 : 0,
            username: this.form.controls.username.value,
            cnpj: this.form.controls.cnpj.value,
            corporate_name: this.form.controls.corporate_name.value,
            business_name: this.form.controls.name.value,
            email: this.form.controls.email.value,
            password: this.form.controls.password.value,
            phone: this.form.controls.phone.value,
            officename: this.form.controls.name.value + ' (' + this.cityName + ')',
            country_code: this.form.controls.country.value,
            city_name: this.cityName,
            city_code: this.cityCode
          }

          var data = await this.data.getIpAddress().toPromise();
          if (data && data['ip']) {
            this.account['ip'] = data['ip'];
          }

          this.data.createAccount(this.account).subscribe(
            (data : any) => {
              this.submitLoading = false;
              if (data && data.error == 'duplicated_email') {
                swal({
                  title: "Warning",
                  text: "The email you entered is already in use, so you will be redirected to the Sign In page.",
                  type: "warning"
                }).then((result) => {
                  window.location.href = environment.appURL + '/signin';
                });
              }
              else {
                swal({
                  title: "Congratulations!",
                  text: "You have created your account successfully. You will be redirected to the Sign In page.",
                  type: "success"
                }).then((result) => {
                  if (this.accountType == 'agency') {
                    window.location.href = environment.appURL + '/signin';
                  }
                  else if (this.accountType == 'school') {
                    this.router.navigate(['welcome']);
                  }
                });
              }
            },
            error => {
              this.submitLoading = false;
              swal("Oops!", "Sorry, but an error has occurred while trying to create your account. Please, try again.", "error");
              return Observable.throw(error);
            }
          );
        } else {
          this.submitLoading = false;
          swal("Warning", "Before creating your account you must read and agree to the terms and conditions by checking the checkbox.", "warning");
        }
      } else {
        this.submitLoading = false;
        swal("Passwords do not match", "The passwords informed are not the same. Please correct them to proceed.", "error");
      }
    } else {
      this.submitLoading = false;
      this.validateAllFormFields(this.form);
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  getErrorMessage(field: string, msg: string) {
    if (field == 'email') {
      if (this.form.get(field).hasError('required')) { return msg; }
      else if (this.form.get(field).hasError('email')) { return 'Please enter a valid email'; }
      else { return ''; }
    }
    else {
      return this.form.get(field).hasError('required') ? msg ? msg : 'Please enter a value' : '';
    }
  }

}
