import { environment } from "../environments/environment";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getCountries() {
    return this.http.get(`${environment.apiURL}/v2/country`);
  }

  createAccount(data) {
    return this.http.post(`${environment.apiURL}/account/signup`, data);
  }

  getIpAddress() {
    return this.http.get(`https://ipapi.co/json/`);
  }
}
